import React,{useContext,useState,useEffect} from 'react'
import { ModalWrapper } from '../addressChangePopup/styles'
import {
  WDNewProfileSidebarMenu,
  WDStyledCancelButtonLabel,
  WDStyledWidgetSubHeading,
} from '../../components/ui/WDTypography'
import { WDStyledCancelButton, WDStyledSaveButton } from '../../components/ui/WDButtons'
import { ButtonRow, CloseWrapper, ContactPopupWrapper, DividerWrapper, DividerWrapper2, HeaderRow, NotesWrapper, PrimaryButtonWrapper, SecondaryButtonWrapper, StyledTableContainer, SubHeadingWrapper } from './styles'
import { SvgCancel } from '../../components/svg/SvgCancel'
import Divider from '../../components/divider/Divider'
import Table from '../../components/table/Table'
import { InputField } from '../inputField/InputField'
import {
  WDStyledSectionTitle,
} from '../../components/ui/WDTypography'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { AccountDetailsFieldValues, AccountDetailsUpdatedValues, ContactInfo, EmailInfo, TableData } from '../../services/accountDetailsContext/AccountDetailsInterface'
import { tableData } from '../../components/dummyTableData';
import { BeneficiaryContext } from '../../services/beneficiaryContext/BeneficiaryContext'

const BeneficiarySubmisionPage = () => {

  const BeneficiaryDetails = useContext(BeneficiaryContext)
//   const accountDetails = BeneficiaryDetails?.accountDetails
  const [updatedData, setUpdatedData] = useState<AccountDetailsUpdatedValues>()
  const [notes, setNotes] = useState('')
  const [mapped,setMapped]=useState<any[]>()
//   console.log("updated data", BeneficiaryDetails?.updatedData)


//   const updateData = BeneficiaryDetails?.accountDetailsUpdatedData


//   const handleNotes = (e: string) => {
//     if (updateData !== undefined) {
//       updateData.notes = e
//       setUpdatedData(updateData)   
//     }
//   }

  useEffect(()=>{
    setMapped(BeneficiaryDetails?.submissionTableData)
  },[BeneficiaryDetails?.submissionTableData])

  // const mappedData: any = BeneficiaryDetails?.submissionTableData
  // // console.log('mapped',mappedData)
  // const updateData = BeneficiaryDetails?.accountDetailsUpdatedData
  // console.log('update data',updateData)

  const columnData1 = [
    {
      Header: 'Field',
      accessor: 'field',
    },
    {
      Header: 'Old value',
      accessor: 'oldValue',
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
    },
  ]

  const tableData = [
    {
      field: 'Name',
      oldValue: 'John Doe',
      newValue: 'Mickhal Doe',
    },
    {
      field: 'Social Security Number',
      oldValue: '123-45-6789',
      newValue: '987-65-4321',
    },
    {
      field: 'Date of Birth',
      oldValue: '01/01/1990',
      newValue: '01/01/1991',
    },
    {
      field: 'Relationship',
      oldValue: 'Brother',
      newValue: 'Brother',
    },
    {
      field: '%Benefits',
      oldValue: '100%',
      newValue: '90%',
    },
  ]

  const columnData2 = [
    {
      Header: 'Field',
      accessor: 'field',
    },
    {
      Header: 'Old value',
      accessor: 'oldValue',
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
    },
  ]
  const tableData2 = [
    {
      field: 'Name',
      oldValue: 'John Doe',
      newValue: 'Mickhal Doe',
    },
    {
      field: 'Social Security Number',
      oldValue: '123-45-6789',
      newValue: '987-65-4321',
    },
    {
      field: 'Date of Birth',
      oldValue: '01/01/1990',
      newValue: '01/01/1991',
    },
    {
      field: 'Relationship',
      oldValue: 'Brother',
      newValue: 'Brother',
    },
    {
      field: '%Benefits',
      oldValue: '100%',
      newValue: '90%',
    },
  ]
//   const handleSubmit = () => {
//     BeneficiaryDetails?.setSubmissionPopUp(false)
//     // console.log('up',updateData)
//     if (updateData !== undefined) {
//       BeneficiaryDetails?.setAccountDetailsSubmissionPayload(updateData)
//     }
//   }
  const reject = () => {
    window.location.reload()
  }

  return (
    <ModalWrapper>
      <ContactPopupWrapper>
        <HeaderRow>
          <WDNewProfileSidebarMenu>Beneficiary Designation</WDNewProfileSidebarMenu>
          <CloseWrapper onClick={() => BeneficiaryDetails?.setSubmissionPopUp(false)}>
            <SvgCancel />
          </CloseWrapper>
        </HeaderRow>
        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>
        <SubHeadingWrapper>
          <WDStyledWidgetSubHeading>
            You are about to change the Beneficiary Designation information.
          </WDStyledWidgetSubHeading>         
        </SubHeadingWrapper>
        <br/>
        <WDNewProfileSidebarMenu> Primary Beneficiary </WDNewProfileSidebarMenu>
        <StyledTableContainer>
          <Table
            tableColumns={columnData1}
            tableData={tableData}
            onClick={() => ''}
          />
        </StyledTableContainer>
       
        <WDNewProfileSidebarMenu> Contingent Beneficiary </WDNewProfileSidebarMenu>
        <StyledTableContainer>
          <Table
            tableColumns={columnData2}
            // tableData={mapped ? mapped : []}
            tableData={tableData2}
            onClick={() => ''}
          />
        </StyledTableContainer>

        <NotesWrapper>
          <InputField
            type="textarea"
            label="Notes"
            onChange={(e) => (e)}
            textboxPlaceholder={'Notes'}
          />
        </NotesWrapper>
        <DividerWrapper2>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper2>

        <ButtonRow>
          <SecondaryButtonWrapper>
            <WDStyledCancelButton onClick={() => {BeneficiaryDetails?.setSubmissionPopUp(false)
            BeneficiaryDetails?.setSubmissionTableData([])
            }}>
              <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
            </WDStyledCancelButton>
          </SecondaryButtonWrapper>
          <PrimaryButtonWrapper>
            <WDStyledSaveButton >
              <WDStyledCancelButtonLabel>{'Submit'}</WDStyledCancelButtonLabel>
            </WDStyledSaveButton>
          </PrimaryButtonWrapper>
        </ButtonRow>
      </ContactPopupWrapper>
    </ModalWrapper>
//     <ModalWrapper>
//     <MasterWrapper>
//       <HeaderRow>
//         <WDNewProfileSidebarMenu>Address</WDNewProfileSidebarMenu>
//         <CloseWrapper onClick={() => AddressChangeContext?.setModal(false)}>
//           <SvgCancel />
//         </CloseWrapper>
//       </HeaderRow>
//       <DividerWrapper>
//         <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
//       </DividerWrapper>
//       <Row>
//         <WDNewProfileSidebarMenu>
//           You are about to change the client account address.
//           <br></br>
//           {AddressValue?.length !== 0 && <p>We found <b>({AddressValue?.length})</b> accounts associated with{' '}
//             <b>{individualFieldValue?.fullName}</b>, select the accounts you
//             want to update the mailing address.</p>}
//           <br></br>
//           {/* We found <StyledBoldLabel>(4)</StyledBoldLabel> accounts associated
//           with <StyledBoldLabel>Mike Smith</StyledBoldLabel>, select the
//           accounts you want to update the mailing address.{' '} */}
//         </WDNewProfileSidebarMenu>
//       </Row>
//       {/* {NewAddress.map((value: any, index: number) => {
//         return ( */}
//       {addressTypeLegal !== undefined && (
//         <>
//           <>
//             <WDStyledPopupLabel>{'Legal Address'}</WDStyledPopupLabel>
//           </>
//           <BoxRow>
//             <AddressChangeWidget
//               newAddress={legal[0]}
//               oldAddress={oldLegal[0]}
//             />
//           </BoxRow>
//         </>
//       )}
//       {addressTypeAlternate !== undefined && (
//         <>
//           <>
//             <WDStyledPopupLabel>{'Temporary Address'}</WDStyledPopupLabel>
//           </>
//           <BoxRow>
//             <AddressChangeWidget
//               alternate={'Alternate'}
//               newAddress={alternateAddress[0]}
//               oldAddress={oldAlternate[0]}
//             />
//           </BoxRow>
//         </>
//       )}
//       <StyledTableContainer>
//         {AddressValuesLength === 2 && (
//           <Table
//             tableColumns={columnData2}
//             tableData={tableData2 ? tableData2 : cardSummary2}
//             setTableData={setTableData2}
//             onClick={() => ''}
//           />
//         )}
//         {AddressValuesLength === 1 && (
//           <Table
//             tableColumns={columnData}
//             tableData={tableData === undefined ? cardSummary : tableData}
//             setTableData={setTableData}
//             onClick={() => ''}
//           />
//         )}
//       </StyledTableContainer>
//       {AddressTableData3?.length === 0 ? (
//         <RowText>
//           <WDNewProfileSidebarMenu>
//             {'There is no Associated Account for this Party'}
//           </WDNewProfileSidebarMenu>
//         </RowText>
//       ) : (
//         ''
//       )}
//       <Row>
//         <InputField
//           type="textarea"
//           label="Notes"
//           onChange={(e) => setNotes(e)}
//           textboxPlaceholder={'Notes'}
//         />
//       </Row>

//       <ButtonRow>
//         <SecondaryButtonWrapper>
//           <WDStyledCancelButton onClick={() => reject()}>
//             <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
//           </WDStyledCancelButton>
//         </SecondaryButtonWrapper>
//         <PrimaryButtonWrapper>
//           <WDStyledSaveButton onClick={() => handleSubmit()}>
//             <WDStyledCancelButtonLabel>{'Submit'}</WDStyledCancelButtonLabel>
//           </WDStyledSaveButton>
//         </PrimaryButtonWrapper>
//       </ButtonRow>
//     </MasterWrapper>
//   </ModalWrapper>
  )
}

export default BeneficiarySubmisionPage