import React, { useContext } from 'react'
import { BeneficiaryContext } from '../../services/beneficiaryContext/BeneficiaryContext'
import { SvgInvestment } from '../../components/svg/SvgInvestment'
import { WDInvestmentSubLabel, WDReadModeTitle, WDStyledCancelButtonLabel, WDStyledSectionTitle, WDStyledEditButtonLabel } from '../../components/ui/WDTypography'
import { MasterWrapper, TopRow, HeaderRow, TitleIconWrap, DividerWrapper, LabelWrap } from '../householdUI/styles'
import { IconWrapper } from '../Tag/styles'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import Divider from '../../components/divider/Divider'
import { COLORS } from '../../theme/Colors'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { FlexItem2, SubHeadingWrapper, Row, TertiaryButtonWrapper, EditWrapper } from '../investment/styles'
import {
    WDStyledCancelButton,
    WDStyledEditButton,
    WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { BeneficiaryEdit } from './BeneficiaryEdit'

export const Beneficiary = () => {
    const beneficiaryFields = useContext(BeneficiaryContext)
    const approval = useContext(ApprovalContext)

    const collapse = () => {
        beneficiaryFields?.setBeneficiaryCollapse(
            !beneficiaryFields?.beneficiaryCollapse
        )
    }

    const editFunction = () => {
        approval?.setIsActiveInv(!approval?.isActiveInv)
        beneficiaryFields?.setBeneficiaryCollapse(true)
    }

    return (
        <MasterWrapper id="beneficiary-designation">
            <TopRow>
                <HeaderRow onClick={() => collapse()}>
                    <TitleIconWrap>
                        <IconWrapper>
                            <SvgInvestment />
                        </IconWrapper>
                        <WDStyledSectionTitle>{'Beneficiary Designation'}</WDStyledSectionTitle>
                    </TitleIconWrap>
                </HeaderRow>
                <TertiaryButtonWrapper>
                    {!approval?.isActiveInv && (
                        <>
                            {!approval?.isActiveAct && !approval?.isActiveAd && !approval?.isActiveAl && (
                                <WDStyledEditButton onClick={() => editFunction()}>
                                    <WDStyledEditButtonLabel> {'Edit'}</WDStyledEditButtonLabel>
                                </WDStyledEditButton>
                            )}
                        </>
                    )}
                    <EditWrapper onClick={() => collapse()}>
                        {beneficiaryFields?.beneficiaryCollapse && <SvgChevronUp />}
                        {!beneficiaryFields?.beneficiaryCollapse && <SvgChevronDown />}
                    </EditWrapper>
                </TertiaryButtonWrapper>
            </TopRow>
            {beneficiaryFields?.beneficiaryCollapse && (
                <DividerWrapper>
                    <Divider
                        bgColor={COLORS.Background.Border}
                        horizontal={true}
                        thickness="1px"
                    />
                </DividerWrapper>
            )}
            {beneficiaryFields?.beneficiaryCollapse && (
                <>{!approval?.isActiveInv && (
                    <>
                        <SubHeadingWrapper>
                            <WDStyledCancelButtonLabel>{'Primary Beneficiary (ies)'}</WDStyledCancelButtonLabel>
                        </SubHeadingWrapper>
                        <Row>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Name</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>Luis Correa</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Social Security Number</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>123-45-6789</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Date of Birth</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>07-12-1984</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Relationship</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>Brother</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>% Benefits</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>100</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                        </Row>
                        <SubHeadingWrapper>
                            <WDStyledCancelButtonLabel>{'Contingent Beneficiary (ies)'}</WDStyledCancelButtonLabel>
                        </SubHeadingWrapper>
                        <Row>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Name</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>Vijay Raja</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Social Security Number</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>123-45-6789</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Date of Birth</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>07-11-1976</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>Relationship</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>Cousin</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                            <FlexItem2>
                                <LabelWrap>
                                    <WDReadModeTitle>% Benefits</WDReadModeTitle>
                                </LabelWrap>
                                <LabelWrap>
                                    <WDInvestmentSubLabel>100</WDInvestmentSubLabel>
                                </LabelWrap>
                            </FlexItem2>
                        </Row>

                    </>
                )}</>
            )}
            {/* Edit Mode */}
            {beneficiaryFields?.beneficiaryCollapse && (
                <>
                    {approval?.isActiveInv && <BeneficiaryEdit />}
                </>
            )}
        </MasterWrapper>
    )


}