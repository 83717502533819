import {
    WDNewProfileSidebarMenu,
    WDStyledCancelButtonLabel,
    WDStyledTableHead,
} from '../../components/ui/WDTypography'
import {
    ButtonRow,
    DividerWrapper2,
    PrimaryButtonWrapper,
    SecondaryButtonWrapper,
    SubHeadingWrapper,
    TextboxWrapper2,
} from '../investment/styles'
import {
    WDStyledCancelButton,
    WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { COLORS } from '../../theme/Colors'
import { useContext, useState } from 'react'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import Divider from '../../components/divider/Divider'
import {
    Box,
    LabelWrap,
    FlexItem,
    FlexItem2,
    Row,
    MandatoryLabel,
    LabelWrap2,
    LabelWrap3,
    FlexItem3,
} from './styles'
import Label from '../../components/label/Label'
import { messages } from '../../shared/constants'
import { InlineErrorWrapper } from '../mailingAddress/styles'
import {
    BeneficiaryContext,
    BeneficiaryUpdate,
} from '../../services/beneficiaryContext/BeneficiaryContext'
import { DropdownField } from '../dropdownField/DropdownField'
import { InputField } from '../inputField/InputField'
import { PlusSquareIcon } from '@chakra-ui/icons'
import BeneficiarySubmitionPage from './BeneficiarySubmisionPage'
import BeneficiarySubmisionPage from './BeneficiarySubmisionPage'

export const BeneficiaryEdit = () => {
    const BeneficiaryDetailsValues = useContext(BeneficiaryContext)
    const [primaryNameErr, setPrimaryNameErr] = useState<any>({})
    const [primarySSNTINErr, setPrimarySSNTINErr] = useState<any>({})
    const [primaryDateOfBirthErr, setPrimaryDateOfBirthErr] = useState<any>({})
    const [primaryRelationshipErr, setPrimaryRelationshipErr] = useState<any>({})
    const [primaryBenefitsErr, setPrimaryBenefitsErr] = useState<any>({})
    const [primaryBenefitsRequiredErr, setPrimaryBenefitsRequiredErr] =
        useState<any>({})
    const [contingentBenefitsRequiredErr, setcontingentBenefitsRequiredErr] =
        useState<any>({})
    const [contingentNameErr, setContingentNameErr] = useState<any>({})
    const [contingentSSNTINErr, setContingentSSNTINErr] = useState<any>({})
    const [contingentDateOfBirthErr, setContingentDateOfBirthErr] = useState<any>(
        {}
    )
    const [contingentRelationshipErr, setContingentRelationshipErr] =
        useState<any>({})
    const [contingentBenefitsErr, setContingentBenefitsErr] = useState<any>({})
    const [totalPrimaryBenefits, setTotalPrimaryBenefits] = useState<string>('')
    const [totalContingentBenefits, setTotalContingentBenefits] =
        useState<string>('')
    const [totalPrimaryBenefitsErr, setTotalPrimaryBenefitsErr] =
        useState<boolean>(false)
    const [totalContingentBenefitsErr, setTotalContingentBenefitsErr] =
        useState<boolean>(false)
    const [relationship, setRelationship] = useState('Select')
    const approval = useContext(ApprovalContext)
    const [primaryNewSSN, setPrimaryNewSSN] = useState<any>({})
    const [contingentNewSSN, setContingentNewSSN] = useState<any>({})

    const [updateData, setUpdateData] = useState<BeneficiaryUpdate[]>([
        {
            name: '',
            ssnOrTin: '',
            dateOfBirth: null,
            relationship: 'Select',
            benefits: '',
        },
    ])

    const [contingentData, setContingentData] = useState<BeneficiaryUpdate[]>([
        {
            name: '',
            ssnOrTin: '',
            dateOfBirth: null,
            relationship: 'Select',
            benefits: '',
        },
    ])

    const addBeneficiary = (dataArray: BeneficiaryUpdate[]) => {
        if (dataArray === updateData) {
            if (updateData.length < 20) {
                setUpdateData([
                    ...updateData,
                    {
                        name: '',
                        ssnOrTin: '',
                        dateOfBirth: null,
                        relationship: 'Select',
                        benefits: '',
                    },
                ])
            } else {
                console.log("can't add more beneficiaries")
            }
        } else {
            if (contingentData.length < 20) {
                setContingentData([
                    ...contingentData,
                    {
                        name: '',
                        ssnOrTin: '',
                        dateOfBirth: null,
                        relationship: 'Select',
                        benefits: '',
                    },
                ])
            } else {
                console.log("can't add more beneficiaries")
            }
        }
    }

    const cancelFunction = () => {
        approval?.setIsActiveInv(!approval?.isActiveInv)
        setUpdateData([
            {
                name: '',
                ssnOrTin: '',
                dateOfBirth: null,
                relationship: 'Select',
                benefits: '',
            },
        ])
        setContingentData([
            {
                name: '',
                ssnOrTin: '',
                dateOfBirth: null,
                relationship: 'Select',
                benefits: '',
            },
        ])
    }

    const checkName = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.name === '') {
                obj[index] = true
            }
        })
        if (dataArray === updateData) {
            setPrimaryNameErr(obj)
        } else {
            setContingentNameErr(obj)
        }
    }

    const checkDateOfBirth = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.dateOfBirth === null) {
                obj[index] = true
            }
        })
        if (dataArray === updateData) {
            setPrimaryDateOfBirthErr(obj)
        } else {
            setContingentDateOfBirthErr(obj)
        }
    }

    const checkRelationshipValue = (dataArray: BeneficiaryUpdate[]) => {
        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item.relationship === '' || item.relationship === 'Select') {
                obj[index] = true
            } else {
                obj[index] = false
            }
        })
        if (dataArray === updateData) {
            setPrimaryRelationshipErr(obj)
        } else {
            setContingentRelationshipErr(obj)
        }
    }

    const checkBenefits = (dataArray: BeneficiaryUpdate[]) => {
        const regex = /^(?:0|[1-9]\d+|)?(?:.?\d{0,1})?$/

        const obj: any = {}
        const reqObj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (String(item.benefits)?.match(regex)) {
                obj[index] = false
            }
            if (item.benefits === '') {
                reqObj[index] = true
            }
        })
        if (dataArray === updateData) {
            setPrimaryBenefitsErr(obj)
            setPrimaryBenefitsRequiredErr(reqObj)
        } else {
            setContingentBenefitsErr(obj)
            setcontingentBenefitsRequiredErr(reqObj)
        }
    }

    const checkSSNTIN = (dataArray: BeneficiaryUpdate[]) => {
        const numbers = /^[0-9]+$/

        const obj: any = {}
        dataArray?.map((item: any, index: any) => {
            if (item?.ssnOrTin) {
                if (item?.ssnOrTin?.match(numbers)) {
                    if (item?.ssnOrTin?.length != 9) {
                        obj[index] = true
                    } else {
                        obj[index] = false
                    }
                } else {
                    obj[index] = true
                }
            }
        })
        if (dataArray === updateData) {
            setPrimarySSNTINErr(obj)
        } else {
            setContingentSSNTINErr(obj)
        }
    }

    const handleChange = (
        e: any,
        name: string,
        index: any,
        dataArray: BeneficiaryUpdate[]
    ) => {
        const values = [...dataArray]
        console.log("dataArray", dataArray)

        if (e !== undefined) {
            if (name === 'Name') {
                values[index].name = e
                checkName(dataArray)
            } else if (name === 'SSN/TIN') {
                values[index].ssnOrTin = e

                const obj: any = {}
                dataArray?.map((item: any, index: any) => {
                    obj[index] = item.ssnOrTin.replace(
                        /(\d{3})(\d{2})(\d{4})/,
                        '$1-$2-$3'
                    )
                })
                if (dataArray === updateData) {
                    setPrimaryNewSSN(obj)
                } else {
                    setContingentNewSSN(obj)
                }

                checkSSNTIN(dataArray)
            } else if (name === 'dateOfBirth') {
                values[index].dateOfBirth = e
                checkDateOfBirth(dataArray)
            } else if (name === 'Benefits') {
                values[index].benefits = e

                let total = 0
                dataArray.forEach(({ benefits }) => {
                    total += Number(benefits)
                    if (dataArray === updateData) {
                        setTotalPrimaryBenefits(String(total))
                    } else {
                        setTotalContingentBenefits(String(total))
                    }
                })
                checkBenefits(dataArray)

                if (dataArray === updateData) {
                    if (Number(total) !== 100) {
                        setTotalPrimaryBenefitsErr(true)
                    } else {
                        setTotalPrimaryBenefitsErr(false)
                    }
                } else {
                    if (Number(total) !== 100) {
                        setTotalContingentBenefitsErr(true)
                    } else {
                        setTotalContingentBenefitsErr(false)
                    }
                }
            }
        }
    }

    const save = () => {
        console.log("entered save func")
        checkName(updateData)
        checkName(contingentData)
        checkDateOfBirth(updateData)
        checkDateOfBirth(contingentData)
        checkRelationshipValue(updateData)
        checkRelationshipValue(contingentData)
        checkSSNTIN(updateData)
        checkSSNTIN(contingentData)
        checkBenefits(updateData)
        checkBenefits(contingentData)
        // BeneficiaryDetailsValues?.setBeneficiaryUpdateData([...updateData]) 
        BeneficiaryDetailsValues?.setSubmissionPopUp(true)
    }

    const handleDropDownChange = (
        e: string | undefined | number,
        data: any,
        dataArray: BeneficiaryUpdate[]
    ) => {
        if (e !== undefined) {
            const relValue = Object.values(e)[0]
            setRelationship(relValue)
            data.relationship = e
            checkRelationshipValue(dataArray)
        }
    }

    const relationshipOptions = [
        {
            value: 'Spouse',
            label: 'Spouse',
        },
        {
            value: 'Spouse > 10 years',
            label: 'Spouse > 10 years',
        },
        {
            value: 'Other',
            label: 'Other',
        },
    ]
    console.log("updayed data", updateData)

    const addRow = (index: any, data: any, dataArray: BeneficiaryUpdate[]) => {
        return (
            <Row key={index}>
                <FlexItem2>
                    <InputField
                        onChange={(e) => handleChange(e, 'Name', index, dataArray)}
                        textboxDefaultValue={data.name}
                        borderColor={
                            (
                                dataArray === updateData
                                    ? primaryNameErr[index]
                                    : contingentNameErr[index]
                            )
                                ? '#BA0517'
                                : '#CBD5E1'
                        }
                        backgroundColor={
                            (
                                dataArray === updateData
                                    ? primaryNameErr[index]
                                    : contingentNameErr[index]
                            )
                                ? '#FEF1EE'
                                : '#FFFFFF'
                        }
                    />
                    {(dataArray === updateData
                        ? primaryNameErr[index]
                        : contingentNameErr[index]) && (
                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                {/* <SvgExclamation /> */}
                                <Label
                                    fontFamily={'SourceSansPro-Regular'}
                                    fontSize={'14px'}
                                    fontStyle={'normal'}
                                    fontWeight={400}
                                    lineHeight={'16px'}
                                    color={'#BA0517'}
                                >
                                    {(
                                        dataArray === updateData
                                            ? primaryNameErr[index]
                                            : contingentNameErr[index]
                                    )
                                        ? messages.address_ui.error_messages.required_field
                                        : ''}
                                </Label>
                            </InlineErrorWrapper>
                        )}
                </FlexItem2>
                <FlexItem2>
                    <InputField
                        onChange={(e) => handleChange(e, 'SSN/TIN', index, dataArray)}
                        textboxDefaultValue={data.ssnOrTin}
                        textboxValue={
                            dataArray === updateData
                                ? primaryNewSSN[index]
                                : contingentNewSSN[index]
                        }
                        borderColor={
                            (
                                dataArray === updateData
                                    ? primarySSNTINErr[index]
                                    : contingentSSNTINErr[index]
                            )
                                ? '#BA0517'
                                : '#CBD5E1'
                        }
                        backgroundColor={
                            (
                                dataArray === updateData
                                    ? primarySSNTINErr[index]
                                    : contingentSSNTINErr[index]
                            )
                                ? '#FEF1EE'
                                : '#FFFFFF'
                        }
                    />
                    {(dataArray === updateData
                        ? primarySSNTINErr[index]
                        : contingentSSNTINErr[index]) && (
                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                {/* <SvgExclamation /> */}
                                <Label
                                    fontFamily={'SourceSansPro-Regular'}
                                    fontSize={'14px'}
                                    fontStyle={'normal'}
                                    fontWeight={400}
                                    lineHeight={'16px'}
                                    color={'#BA0517'}
                                >
                                    {(
                                        dataArray === updateData
                                            ? primarySSNTINErr[index]
                                            : contingentSSNTINErr[index]
                                    )
                                        ? messages.beneficiary_ui.ssn_error.ssn_invalid
                                        : ''}
                                </Label>
                            </InlineErrorWrapper>
                        )}
                </FlexItem2>
                <FlexItem2>
                    <InputField
                        onChange={(e) => handleChange(e, 'dateOfBirth', index, dataArray)}
                        type="Date"
                        textboxDefaultValue={data.dateOfBirth}
                        // value={data.dateOfBirth}
                        borderColor={
                            (
                                dataArray === updateData
                                    ? primaryDateOfBirthErr[index]
                                    : contingentDateOfBirthErr[index]
                            )
                                ? '#BA0517'
                                : '#CBD5E1'
                        }
                        backgroundColor={
                            (
                                dataArray === updateData
                                    ? primaryDateOfBirthErr[index]
                                    : contingentDateOfBirthErr[index]
                            )
                                ? '#FEF1EE'
                                : '#FFFFFF'
                        }
                    />
                    {(dataArray === updateData
                        ? primaryDateOfBirthErr[index]
                        : contingentDateOfBirthErr[index]) && (
                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                {/* <SvgExclamation /> */}
                                <Label
                                    fontFamily={'SourceSansPro-Regular'}
                                    fontSize={'14px'}
                                    fontStyle={'normal'}
                                    fontWeight={400}
                                    lineHeight={'16px'}
                                    color={'#BA0517'}
                                >
                                    {(
                                        dataArray === updateData
                                            ? primaryDateOfBirthErr[index]
                                            : contingentDateOfBirthErr[index]
                                    )
                                        ? messages.address_ui.error_messages.required_field
                                        : ''}
                                </Label>
                            </InlineErrorWrapper>
                        )}
                </FlexItem2>
                <FlexItem2>
                    <DropdownField
                        options={relationshipOptions}
                        defaultValue={{
                            value: data.relationship,
                            label: data.relationship,
                        }}
                        onChange={(e) => handleDropDownChange(e, data, dataArray)}
                        borderColor={
                            (
                                dataArray === updateData
                                    ? primaryRelationshipErr[index]
                                    : contingentRelationshipErr[index]
                            )
                                ? '#BA0517'
                                : '#CBD5E1'
                        }
                        backgroundColor={
                            (
                                dataArray === updateData
                                    ? primaryRelationshipErr[index]
                                    : contingentRelationshipErr[index]
                            )
                                ? '#FEF1EE'
                                : '#FFFFFF'
                        }
                    />
                    {(dataArray === updateData
                        ? primaryRelationshipErr[index]
                        : contingentRelationshipErr[index]) && (
                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                {/* <SvgExclamation /> */}
                                <Label
                                    fontFamily={'SourceSansPro-Regular'}
                                    fontSize={'14px'}
                                    fontStyle={'normal'}
                                    fontWeight={400}
                                    lineHeight={'16px'}
                                    color={'#BA0517'}
                                >
                                    {(
                                        dataArray === updateData
                                            ? primaryRelationshipErr[index]
                                            : contingentRelationshipErr[index]
                                    )
                                        ? messages.address_ui.error_messages.required_field
                                        : ''}
                                </Label>
                            </InlineErrorWrapper>
                        )}
                </FlexItem2>
                <FlexItem2>
                    <InputField
                        onChange={(e) => handleChange(e, 'Benefits', index, dataArray)}
                        textboxDefaultValue={data.benefits}
                        borderColor={
                            (
                                dataArray === updateData
                                    ? primaryBenefitsErr[index] ||
                                    primaryBenefitsRequiredErr[index]
                                    : contingentBenefitsErr[index] ||
                                    contingentBenefitsRequiredErr[index]
                            )
                                ? '#BA0517'
                                : '#CBD5E1'
                        }
                        backgroundColor={
                            (
                                dataArray === updateData
                                    ? primaryBenefitsErr[index] ||
                                    primaryBenefitsRequiredErr[index]
                                    : contingentBenefitsErr[index] ||
                                    contingentBenefitsRequiredErr[index]
                            )
                                ? '#FEF1EE'
                                : '#FFFFFF'
                        }
                    />
                    {(dataArray === updateData
                        ? primaryBenefitsErr[index]
                        : contingentBenefitsErr[index]) && (
                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                {/* <SvgExclamation /> */}
                                <Label
                                    fontFamily={'SourceSansPro-Regular'}
                                    fontSize={'14px'}
                                    fontStyle={'normal'}
                                    fontWeight={400}
                                    lineHeight={'16px'}
                                    color={'#BA0517'}
                                >
                                    {(
                                        dataArray === updateData
                                            ? primaryBenefitsErr[index]
                                            : contingentBenefitsErr[index]
                                    )
                                        ? messages.beneficiary_ui.benefits_error.benefits_invalid
                                        : ''}
                                </Label>
                            </InlineErrorWrapper>
                        )}
                    {(dataArray === updateData
                        ? primaryBenefitsRequiredErr[index]
                        : contingentBenefitsRequiredErr[index]) && (
                            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                {/* <SvgExclamation /> */}
                                <Label
                                    fontFamily={'SourceSansPro-Regular'}
                                    fontSize={'14px'}
                                    fontStyle={'normal'}
                                    fontWeight={400}
                                    lineHeight={'16px'}
                                    color={'#BA0517'}
                                >
                                    {(
                                        dataArray === updateData
                                            ? primaryBenefitsRequiredErr[index]
                                            : contingentBenefitsRequiredErr[index]
                                    )
                                        ? messages.address_ui.error_messages.required_field
                                        : ''}
                                </Label>
                            </InlineErrorWrapper>
                        )}
                </FlexItem2>
            </Row>
        )
    }

    const getHeading = (label: string) => {
        return (
            <SubHeadingWrapper>
                <WDStyledCancelButtonLabel>{label}</WDStyledCancelButtonLabel>
            </SubHeadingWrapper>
        )
    }

    const getTableHeader = () => {
        return (
            <Row>
                <FlexItem>
                    <WDStyledTableHead>
                        <LabelWrap2>
                            <WDNewProfileSidebarMenu>Name</WDNewProfileSidebarMenu>
                            <MandatoryLabel>{' *'}</MandatoryLabel>
                        </LabelWrap2>
                    </WDStyledTableHead>
                </FlexItem>
                <FlexItem>
                    <WDStyledTableHead>SSN/TIN</WDStyledTableHead>
                </FlexItem>
                <FlexItem>
                    <WDStyledTableHead>
                        <LabelWrap2>
                            <WDNewProfileSidebarMenu>Date of Birth</WDNewProfileSidebarMenu>
                            <MandatoryLabel>{' *'}</MandatoryLabel>
                        </LabelWrap2>
                    </WDStyledTableHead>
                </FlexItem>
                <FlexItem>
                    <WDStyledTableHead>
                        <LabelWrap2>
                            <WDNewProfileSidebarMenu>Relationship</WDNewProfileSidebarMenu>
                            <MandatoryLabel>{' *'}</MandatoryLabel>
                        </LabelWrap2>
                    </WDStyledTableHead>
                </FlexItem>
                <FlexItem>
                    <WDStyledTableHead>
                        <LabelWrap2>
                            <WDNewProfileSidebarMenu>% Benefits</WDNewProfileSidebarMenu>
                            <MandatoryLabel>{' *'}</MandatoryLabel>
                        </LabelWrap2>
                    </WDStyledTableHead>
                </FlexItem>
            </Row>
        )
    }

    const getTotalBenefits = (dataArray: BeneficiaryUpdate[]) => {
        return (
            <>
                <Row>
                    <PlusSquareIcon
                        color="blue"
                        marginLeft="7px"
                        onClick={() => addBeneficiary(dataArray)}
                    />
                    <FlexItem3>
                        <LabelWrap3>Total &nbsp;</LabelWrap3>
                        <InputField
                            textboxValue={
                                dataArray === updateData
                                    ? totalPrimaryBenefits
                                    : totalContingentBenefits
                            }
                            onChange={() => console.log('')}
                            borderColor={
                                (
                                    dataArray === updateData
                                        ? totalPrimaryBenefitsErr
                                        : totalContingentBenefitsErr
                                )
                                    ? '#BA0517'
                                    : '#CBD5E1'
                            }
                            backgroundColor={
                                (
                                    dataArray === updateData
                                        ? totalPrimaryBenefitsErr
                                        : totalContingentBenefitsErr
                                )
                                    ? '#FEF1EE'
                                    : '#FFFFFF'
                            }
                        />
                    </FlexItem3>
                </Row>
                <Row>
                    <FlexItem3>
                        {(dataArray === updateData
                            ? totalPrimaryBenefitsErr
                            : totalContingentBenefitsErr) && (
                                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                                    {/* <SvgExclamation /> */}
                                    <Label
                                        fontFamily={'SourceSansPro-Regular'}
                                        fontSize={'14px'}
                                        fontStyle={'normal'}
                                        fontWeight={400}
                                        lineHeight={'16px'}
                                        color={'#BA0517'}
                                    >
                                        {(
                                            dataArray === updateData
                                                ? totalPrimaryBenefitsErr
                                                : totalContingentBenefitsErr
                                        )
                                            ? messages.beneficiary_ui.total_benefits_error.total_invalid
                                            : ''}
                                    </Label>
                                </InlineErrorWrapper>
                            )}
                    </FlexItem3>
                </Row>
            </>
        )
    }

    return (
        <>
            {getHeading(messages.beneficiary_ui.primary_title)}

            <Box>
                {getTableHeader()}
                {updateData.map((data, index) => (
                    <Row key={index}>{addRow(index, data, updateData)}</Row>
                ))}
                {getTotalBenefits(updateData)}
            </Box>

            {getHeading(messages.beneficiary_ui.contingent_title)}
            <Box>
                {getTableHeader()}
                {contingentData.map((data, index) => (
                    <Row key={index}>{addRow(index, data, contingentData)}</Row>
                ))}
                {getTotalBenefits(contingentData)}
            </Box>

            <DividerWrapper2>
                <Divider
                    bgColor={COLORS.Background.Border}
                    horizontal={true}
                    thickness="1px"
                />
            </DividerWrapper2>

            <ButtonRow>
                <SecondaryButtonWrapper>
                    <WDStyledCancelButton onClick={cancelFunction}>
                        <WDStyledCancelButtonLabel>
                            {messages.account_details_ui.buttons.cancel}
                        </WDStyledCancelButtonLabel>
                    </WDStyledCancelButton>
                </SecondaryButtonWrapper>
                <PrimaryButtonWrapper>
                    <WDStyledSaveButton onClick={() => save()}>
                        <WDStyledCancelButtonLabel>
                            {messages.account_details_ui.buttons.save}
                        </WDStyledCancelButtonLabel>
                    </WDStyledSaveButton>
                </PrimaryButtonWrapper>
            </ButtonRow>
            {BeneficiaryDetailsValues?.submissionPopUp && (
                <BeneficiarySubmisionPage />
            )}
        </>
    )
}
