import React, { createContext, useState } from 'react'

interface BeneficiaryContextInterface {
  beneficiaryCollapse: boolean
  setBeneficiaryCollapse: SetBeneficiaryCollapse
  submissionPopUp: boolean
  setSubmissionPopUp: SetBeneficiaryCollapse
  submissionTableData: TableData[] | undefined
  setSubmissionTableData: SetSubmissionTableData
  // BeneficiaryUpdateData : BeneficiaryUpdate[] | undefined
  // setBeneficiaryUpdateData : setBeneficiaryUpdateData
}
interface SetBeneficiaryCollapse {
  (value: boolean): void | boolean
}
interface SetSubmissionTableData {
  (value: TableData[]): void | TableData[]
}

// interface setBeneficiaryUpdateData {
//   (value : BeneficiaryUpdate[]) : void | BeneficiaryUpdate[]
// }
export interface TableData {
  field?: string | undefined
  oldValue?: string | undefined
  newValue?: string | undefined
}
interface SetSubmissionPopUp {
  (value: boolean): void | boolean
}

export interface BeneficiaryUpdate {
  name: string
  ssnOrTin: string
  dateOfBirth: any
  relationship: any
  benefits: string
}

export const BeneficiaryContext = createContext<
  BeneficiaryContextInterface | undefined | null
>(undefined)

interface BeneficiaryCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const BeneficiaryContextProvider = ({
  children,
}: BeneficiaryCtxProviderProps) => {
  const [submissionPopUp, setSubmissionPopUp] = useState(false)
  const [beneficiaryCollapse, setBeneficiaryCollapse] = useState(false)
  const [submissionTableData, setSubmissionTableData] = useState<TableData[]>()
  // const [BeneficiaryUpdateData, setBeneficiaryUpdateData] = useState<BeneficiaryUpdate[]>()

  return (
    <BeneficiaryContext.Provider
      value={{
        beneficiaryCollapse,
        setBeneficiaryCollapse,
        submissionPopUp,
        setSubmissionPopUp,
        submissionTableData,
        setSubmissionTableData,
        // BeneficiaryUpdateData,
        // setBeneficiaryUpdateData,
      }}
    >
      {children}
    </BeneficiaryContext.Provider>
  )
}
